import React from "react";
import PropTypes from "prop-types";

function ErrorDisplay({ message }) {
    return (
        <div style={{ color: "red" }}>
            <p>Error: {message}</p>
        </div>
    );
}

ErrorDisplay.propTypes = {
    message: PropTypes.string.isRequired,
};

export default ErrorDisplay;

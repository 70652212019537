import React, { useState } from "react";
import { PayPalScriptProvider } from "@paypal/react-paypal-js";
import PayPalCheckout from "./paypalCheckout";
import PatientProfileForm from "./patientProfileForm";

function Checkout() {
    const [formValid, setFormValid] = useState(false);

    return (
        <>
            <PatientProfileForm setFormValid={setFormValid} />
            <PayPalScriptProvider>{formValid && <PayPalCheckout />}</PayPalScriptProvider>
        </>
    );
}

export default Checkout;

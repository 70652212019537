const contentData = {
    homePage: {
        title: "التسجيل",
        subtitle: "يرجى ملء النموذج للمتابعة",
        tetsDescription: "وصف الاختبار",
        useWebBrowserInsteadOfMobile: "يرجى استخدام متصفح الويب بدلاً من الجهاز المحمول.",
        form: {
            subtitle: "معلومات شخصية",
            licenseNumber: {
                label: "رمز الترخيص",
                placeholder: "",
            },
            lastName: {
                label: "إسم العائلة",
                placeholder: "",
            },
            firstName: {
                label: "الإسم الشخصي",
                placeholder: "",
            },
            idNumber: {
                label: "رقم الهوية",
            },
            emailAddress: {
                label: "بريد إلكتروني",
            },
            submit: "إحفظ",
            sex: {
                label: "الجنس",
                male: "ذكر",
                female: "أنثى",
            },
            birthDate: {
                label: "تاريخ الميلاد",
            },
            acceptTerms: {
                label: "أوافق على",
                link: "الشروط والأحكام",
            },
            invalidAge: "يرجى تقديم تاريخ ميلاد صالح (يجب أن يكون العمر 6 سنوات أو أكثر)",
            proceedToPayment: "عرض إمكانيات الدفع",
            paymentInformation: "تكلفة الفحص",
            paymentInfoLoading: "تحميل تكلفة الفحص",
            ErrorWhileRetrievingPaymentInformation: "حدث خطأ أثناء استرداد تكلفة الفحص",
        },
    },
};

export default contentData;

import Cookies from "js-cookie";

export function instructionsPage() {
    const link = document.createElement("a");
    link.id = "testLink";
    link.href = `${process.env.REACT_APP_KESHEV_PREV_SYS_URL}/instruction.php`;
    link.target = "_blank";
    link.click();
}

export function setKeshevCookie(keshevServerCookie) {
    const expirationMatch = keshevServerCookie[1].match(/expires=(.*?);/);
    const expirationDate = expirationMatch ? new Date(expirationMatch[1]) : undefined;

    // Set PHPSESSID
    Cookies.set(keshevServerCookie[0].split(";")[0].split("=")[0], keshevServerCookie[0].split(";")[0].split("=")[1], {
        path: "/",
        domain: process.env.REACT_APP_KESHEV_SYS_DOMAIN,
    });

    // Set keshevCookie
    Cookies.set(keshevServerCookie[1].split(";")[0].split("=")[0], keshevServerCookie[1].split(";")[0].split("=")[1], {
        path: "/",
        domain: process.env.REACT_APP_KESHEV_SYS_DOMAIN,
        expires: expirationDate,
    });
}

import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Form, Button, Alert, Col, Row } from "react-bootstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useStores } from "../stores/RootStore";
import { isValidEmail, isSelectedDobDateValid } from "../services/validation";
import usePaymentInfo from "../hooks/usePaymentInfo";
import contentData from "../services/content";
import logo from "../assest/logo.png";

function PatientProfileForm({ setFormValid }) {
    const { PatientStore } = useStores();
    const { updatePatientData } = PatientStore;
    const [email, setEmail] = useState("");
    const [gender, setGender] = useState("");
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [isNewEmailValid, setIsNewEmailValid] = useState(false);
    const [isDobValid, setIsDobValid] = useState(false);
    const [validated, setValidated] = useState(false);
    const [showForm, setShowForm] = useState(false);
    const [isChecked, setChecked] = useState(false);
    const [testCost, setTestCost] = useState("");
    const [currency, setCurrency] = useState("");

    const { paymentInfo, loading, error } = usePaymentInfo();

    const {
        emailAddress,
        birthDate,
        paymentInformation,
        ErrorWhileRetrievingPaymentInformation,
        subtitle,
        sex,
        proceedToPayment,
        paymentInfoLoading,
        acceptTerms,
        invalidAge,
    } = contentData.homePage.form;

    const { tetsDescription } = contentData.homePage;

    useEffect(() => {
        if (!loading && !error) {
            setShowForm(true);
            setCurrency(paymentInfo.currency_code);
            setTestCost(paymentInfo.value);
        }
    }, [loading, error, paymentInfo]);

    const validateForm = () => {
        const validEmail = isValidEmail(email);
        const validDob = isSelectedDobDateValid(selectedDate);
        setIsNewEmailValid(validEmail);
        setIsDobValid(validDob);

        return validDob && gender && validEmail && isChecked;
    };

    const setPatientStore = () => {
        updatePatientData({ key: "dob", value: selectedDate });
        updatePatientData({ key: "gender", value: gender });
        updatePatientData({ key: "email", value: email });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        setValidated(true);

        const isFormValid = validateForm();

        setFormValid(isFormValid);
        if (isFormValid) {
            setPatientStore();
        }
    };

    return (
        <div className="rtl-form mt-4">
            <img src={logo} style={{ width: "200px" }} className="mb-4" alt="" />
            <h2 className="mb-4">{subtitle}</h2>
            <p className="mb-4">{tetsDescription}</p>
            {loading && <p className="mb-4">{paymentInfoLoading}...</p>}
            {error && (
                <p className="mb-4">
                    {ErrorWhileRetrievingPaymentInformation}: {error}
                </p>
            )}
            {showForm && (
                <Form noValidate validated={validated} onSubmit={handleSubmit}>
                    <Row className="mb-3">
                        <Form.Group as={Col} sm="6" md="5" controlId="dob">
                            <Form.Label column md="3">
                                {birthDate.label}
                            </Form.Label>
                            <DatePicker
                                className="mx-2"
                                selected={selectedDate}
                                onChange={(date) => setSelectedDate(date)}
                                dateFormat="MM/dd/yyyy"
                                maxDate={new Date()}
                                showYearDropdown
                                scrollableYearDropdown
                                yearDropdownItemNumber={100}
                                customInput={
                                    <Form.Control
                                        isInvalid={validated && !isDobValid}
                                        style={{ borderColor: validated && !isDobValid ? "#dc3545" : "" }}
                                    />
                                }
                            />
                            {!isDobValid && validated && (
                                <Alert variant="danger" className="mt-2 mb-1">
                                    {invalidAge}
                                </Alert>
                            )}
                        </Form.Group>
                    </Row>

                    <Row className="mb-3">
                        <Form.Group as={Col} sm="6" md="5" controlId="gender">
                            <Row>
                                <Form.Label column md="3">
                                    {sex.label}
                                </Form.Label>
                                <Col>
                                    <Form.Select
                                        value={gender}
                                        onChange={(e) => setGender(e.target.value)}
                                        required
                                        isInvalid={validated && !gender}
                                    >
                                        <option value="" />
                                        <option value="M">{sex.male}</option>
                                        <option value="F">{sex.female}</option>
                                    </Form.Select>
                                </Col>
                            </Row>
                        </Form.Group>
                    </Row>

                    <Row className="mb-3">
                        <Form.Group as={Col} sm="6" md="5" controlId="email">
                            <Row>
                                <Form.Label column md="3">
                                    {emailAddress.label}
                                </Form.Label>
                                <Col>
                                    <Form.Control
                                        type="email"
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                        required
                                        isInvalid={validated && !isNewEmailValid}
                                    />
                                </Col>
                            </Row>
                        </Form.Group>
                    </Row>
                    <Row className="mb-3">
                        <Form.Group as={Col} sm="6" md="5" controlId="paymentInfo" className="mb-3">
                            <Row>
                                <Form.Label column md="3">
                                    {paymentInformation}
                                </Form.Label>
                                <Form.Text className="col-md-8 py-2" style={{ fontWeight: 700 }}>
                                    {`${Number(testCost).toFixed(2)} ${currency}`}
                                </Form.Text>
                            </Row>
                        </Form.Group>
                    </Row>
                    <Row className="mb-3">
                        <Form.Group as={Col} sm="6" md="5" controlId="gender">
                            <Row>
                                <Form.Check
                                    className="col-md-1"
                                    type="checkbox"
                                    checked={isChecked}
                                    onChange={() => setChecked(!isChecked)}
                                    required
                                    isInvalid={validated && !isChecked}
                                    id="terms"
                                />
                                <Form.Label className="col py-0 px-0" htmlFor="terms">
                                    <span>{acceptTerms.label} </span>
                                    <a href={process.env.REACT_APP_WIX_URL} target="_blank" rel="noreferrer noopener">
                                        {acceptTerms.link}
                                    </a>
                                </Form.Label>
                            </Row>
                        </Form.Group>
                    </Row>
                    <Button variant="primary" type="submit" className="mb-3">
                        {proceedToPayment}
                    </Button>
                </Form>
            )}
        </div>
    );
}

PatientProfileForm.propTypes = {
    setFormValid: PropTypes.func.isRequired,
};

export default PatientProfileForm;

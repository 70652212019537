import React from "react";
import PatientStore from "./PatientStore";

class RootStore {
    constructor() {
        this.PatientStore = new PatientStore(this);
    }
}
const StoresContext = React.createContext(new RootStore());

export const useStores = () => React.useContext(StoresContext);

import { useState, useEffect } from "react";

const usePaymentInfo = () => {
    const [paymentInfo, setPaymentInfo] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchPaymentInfo = async () => {
            try {
                const response = await fetch(
                    `${process.env.REACT_APP_KESHEV_B2C_API_URL}keshev-b2c-api/getPaymentInfo`,
                    {
                        method: "GET",
                        headers: {
                            "Content-Type": "application/json",
                            client_id: process.env.REACT_APP_KESHEV_B2C_API_CLIENT_ID,
                        },
                    }
                );

                if (!response.ok) {
                    throw new Error("Failed to fetch payment information");
                }

                const data = await response.json();
                setPaymentInfo(data);
            } catch (err) {
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };

        fetchPaymentInfo();
    }, []);

    return { paymentInfo, loading, error };
};

export default usePaymentInfo;

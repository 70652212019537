import React, { useState } from "react";
import { PayPalButtons, usePayPalScriptReducer } from "@paypal/react-paypal-js";
import ErrorDisplay from "./errorDisplay";
import usePayPalFunctions from "../hooks/usePayPalFunctions";

function PayPalCheckout() {
    const [{ isRejected, isPending }] = usePayPalScriptReducer();
    const [error, setError] = useState(false);
    const { createOrder, onApprove } = usePayPalFunctions({ setError });

    return (
        <div className="row justify-content-end w-50 mt-4">
            {isPending && <div className="spinner" />}
            {(isRejected || error) && <ErrorDisplay message="Something went wrong. Please try again later." />}
            {!error && (
                <PayPalButtons
                    createOrder={createOrder}
                    onApprove={onApprove}
                    disabled={isPending}
                    onError={() => setError(true)}
                />
            )}
        </div>
    );
}

export default PayPalCheckout;

import { useStores } from "../stores/RootStore";
import { instructionsPage, setKeshevCookie } from "../services/keshevPrevSys";

function usePayPalFunctions({ setError }) {
    const { PatientStore } = useStores();
    const { gender, dob, email } = PatientStore;

    const createOrder = async (data) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_KESHEV_B2C_API_URL}keshev-b2c-api/createOrder`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    client_id: process.env.REACT_APP_KESHEV_B2C_API_CLIENT_ID,
                },
                body: JSON.stringify({
                    orderID: data.orderID,
                    gender,
                    dob,
                    email,
                }),
            });
            if (!response.ok) {
                // console.log("CreateOrder - response isnt ok", response);
                setError(true);
                return undefined;
            }
            const orderData = await response.json();
            const orderId = orderData?.data.id;
            if (!orderId) {
                setError(true);
                return undefined;
            }
            return orderId;
        } catch (error) {
            // console.log("createOrder - error", error.message || error);
            setError(true);
            return undefined;
        }
    };

    const onApprove = async (data) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_KESHEV_B2C_API_URL}keshev-b2c-api/approveOrder`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    client_id: process.env.REACT_APP_KESHEV_B2C_API_CLIENT_ID,
                },
                body: JSON.stringify({
                    orderID: data.orderID,
                    gender,
                    dob,
                    email,
                }),
                withCredentials: true,
            });
            if (!response.ok) {
                // console.log("OnApprove - response isnt ok", response);
                setError(true);
                return;
            }
            const keshevCookie = await response.json();
            // console.log("keshevCookie", keshevCookie.message);

            setKeshevCookie(keshevCookie.message);
            instructionsPage();
        } catch (error) {
            // console.log("createOrder - error", error.message || error);
            setError(true);
        }
    };

    return {
        createOrder,
        onApprove,
    };
}

export default usePayPalFunctions;

import React, { useState } from "react";
import Checkout from "./components/checkout";
import MobileWarning from "./components/mobileWarning";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";

function App() {
    const [isMobile, setIsMobile] = useState(false);

    return (
        <div className="App container">
            <MobileWarning isMobile={isMobile} setIsMobile={setIsMobile} />
            {!isMobile && <Checkout />}
        </div>
    );
}

export default App;

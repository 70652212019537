export function isValidEmail(email) {
    const emailRegex =
        /^(([^<>()\[\]\\.,;:&\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return emailRegex.test(email);
}

export function isSelectedDobDateValid(selectedDate) {
    const currentDate = new Date();
    const age = currentDate.getFullYear() - selectedDate.getFullYear();
    return !!(age >= 6);
}

import React, { useEffect } from "react";
import PropTypes from "prop-types";
import Alert from "react-bootstrap/Alert";
import contentData from "../services/content";

function MobileWarning({ isMobile, setIsMobile }) {
    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 768);
        };
        handleResize();
        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    return isMobile ? (
        <Alert key="mobile-warning" variant="warning" className="mt-4">
            <p>{contentData.homePage.useWebBrowserInsteadOfMobile}</p>
        </Alert>
    ) : null;
}

MobileWarning.propTypes = {
    isMobile: PropTypes.bool.isRequired,
    setIsMobile: PropTypes.func.isRequired,
};

export default MobileWarning;
